html {
  font-size: 62.5%;
}

html,
body {
  height: 100%;
}
#root {
  height: 100%;
}
* {
  box-sizing: border-box;
  font-family: Roboto Flex, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

body {
  margin: 0;
  font-family: Roboto Flex, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.49px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --PRIMARY: #142d50;
  --SECONDARY: #142d50bf;
  --WHITE: #fff;
  --YELLOW: #ffbf00;
  --LOGIN-FORM-BORDER: #142d50;
  --LOGIN-BOX-SHADOW: #10182808;
  --LOGIN-BG: #0c1426;
  --PLACEHOLDER-TEXT: #9c9c9e;
  --TABLE-HEADER: #101828;
  --TABLE-BORDER: #e4e7ec;
  --BLACK: #000;
  --GREEN: #34a853;
  --TEXT-ACTIVE: #008675;
  --TEXT-INACTIVE: #e4002b;
  --TEXT-INVITED: #ff9500;
  --TEXT-VIEWER: #f97316;
  --TEXT-ADMIN: #d70adb;
  --TEXT-PERMANENT: #e4002b;
  --TEXT-TEMPORARY: #ff9500;
  --PERMANENT: #e4002b1a;
  --TEMPORARY: #fcbe0c1a;
  --VIEWER: #f973161a;
  --ADMIN: #dc00e01a;
  --ACTIVE: rgba(0, 134, 117, 0.1);
  --INACTIVE: rgba(252, 229, 234, 1);
  --INVITED: #fcbe0c1a;
  --EXPIRED: #fcbe0c1a;
  --TEXT-EXPIRED: #ff9500;
  --PRODUCT: #0085ad1a;
  --TEXT-PRODUCT: #0085ad;
  --PLATFORM: #84329b1a;
  --TEXT-PLATFORM: #84329b;
  --BORDER: #142d5040;
  --NEUTRAL-4: #656f7b;
  --PAGE-NOT-FOUND-BG: #8995a7;
  --TEXT-HIGHLIGHT: #ffbf00;
  --TEXT-VIEWER: #f97316;
  --INDIVIDUAL: #0085ad1a;
  --TEXT-INDIVIDUAL: #007aff;
  --TEXT-SUPERADMIN: #84329b;
  --EXPIRED: #142d501a;
  --TEXT-EXPIRED: #142d50;
  --VIEWER: #f973161a;
  --TRANSPARENT: rgba(99, 113, 121, 0.6);
  --BREADCRUMB-ITEM1: #343434e0;
  --COUNT-BADGE: #142d501a;
  --USER-DETAIL-LABEL: #8996a7;
  --ERROR: #e4002b;
  --DELETE: #ff4d4f;
  --BORDER-DARK: #142d50bf;
  --PAGE-BORDER: #e2e8f0;
  --ELLIPSE: #8996a7;
  --TABLE-ROW-HOVER: #f6faff;
  --NODE-TEXT: #3b4252;
  --SA: #9747ff26;
  --TEXT-SA: #9747ff;
  --SUPER-ADMIN: var(--SA);
  --TEXT-SUPER-ADMIN: var(--TEXT-SA);
  --ENABLED: var(--ACTIVE);
  --TEXT-ENABLED: var(--TEXT-ACTIVE);
  --RED: #e4002b;
}

@font-face {
  font-family: "Roboto Flex";
  src: local("Roboto Flex"),
    url("./assets/fonts/RobotoFlex.ttf") format("truetype");
}

/* width */
::-webkit-scrollbar {
  width: 0.7rem;
  height: 0.8rem;
  margin-bottom: 1rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--BORDER);
  border-radius: 0.9rem;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Common styles that can be reused in components */
.d-flex {
  display: flex;
}
.place-center {
  place-content: center;
}
.m-auto {
  margin: auto;
}
.mb-2 {
  margin-bottom: 2.4rem;
}
.modal-title .ant-modal-title {
  font-size: 2.8rem;
  color: var(--PRIMARY);
  font-weight: 500;
}
.modal-title {
  .sub-text {
    margin-top: 0;
    font-weight: 300;
    font-size: 1.8rem;
    color: var(--SECONDARY);
  }
  .ant-modal-footer {
    & button {
      min-width: 10rem;
    }
  }
}
.search-inp {
  height: 4.4rem !important;
}
.cursor-pointer {
  cursor: pointer;
}
.upload-btn {
  display: flex;
  margin-bottom: 2rem;
  display: flex;
  gap: 1.6rem;
  Button {
    height: 3.6rem;
  }
  .upload {
    background: #fcc601;
    color: var(--PRIMARY);
    font-weight: 600;
    /* Media query for max width 1400px */
  }
}
.space {
  padding: 0 2rem !important;
}
.space-xs {
  padding: 0 0.5rem !important;
}
.error-btn {
  background: var(--ERROR);
  color: var(--WHITE);
  border: none !important;
}
.error-btn:hover {
  background: var(--ERROR) !important;
  color: var(--WHITE) !important;
  opacity: 0.8;
  border: none !important;
}
.error-btn:disabled {
  background-color: #142d5040 !important;
  opacity: 1 !important;
}
.delete-btn {
  background: var(--DELETE);
  color: var(--WHITE);
  border: none !important;
}
.delete-btn:hover {
  background: var(--DELETE) !important;
  color: var(--WHITE) !important;
  opacity: 0.8;
  border: none !important;
}
.red-text {
  color: var(--RED);
}

/* Overriden styles of antd components */
.ant-form-item-label {
  padding-bottom: 0.6rem !important;
}
.ant-form-item-label > label {
  color: var(--PRIMARY) !important;
  font-weight: 500 !important;
}
.ant-input::placeholder {
  color: var(--PLACEHOLDER-TEXT) !important;
  font-size: 1.6rem;
}
.ant-form-item-control-input input,
.ant-input {
  height: 4.8rem !important;
}
.ant-btn {
  border-radius: 8px;
}
.ant-btn-primary,
.ant-btn-default {
  box-shadow: none !important;
}
.ant-btn-primary:disabled {
  background: var(--PRIMARY);
  opacity: 0.7;
  color: var(--WHITE);
}
.ant-table-row:hover {
  background: var(--TABLE-ROW-HOVER) !important;
}
.ant-table-content table {
  border: 1px solid var(--TABLE-BORDER);
  border-radius: 0.6rem;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid var(--TABLE-BORDER);
  border-collapse: collapse;
  color: var(--TABLE-HEADER);
  font-size: 1.5rem;
}
.ant-table-tbody > tr:last-child > td {
  border-bottom: none;
  border-collapse: collapse;
}

.ant-table-thead .ant-table-cell {
  color: var(--TABLE-HEADER) !important;
  font-weight: 600;
  font-size: 1.4rem;
  background: none !important;
  &::before {
    content: none !important;
  }
}

.ant-table-cell {
  padding: 1.2rem 2.4rem !important;
}

.ant-pagination-item {
  a {
    color: var(--PRIMARY) !important;
  }
}

.ant-pagination-item-active {
  background-color: var(--PRIMARY) !important;
  a {
    color: var(--WHITE) !important;
  }
}
.toast-message {
  background-color: var(--PRIMARY);
  box-shadow: 0rem 0.8rem 1rem 0rem #00000033 !important;
  border-radius: 0.6rem !important;
}
.ant-notification-bottomRight {
  bottom: 6rem !important;
}
.ant-notification-topRight {
  top: 6rem !important;
}
.ant-notification-notice-description {
  font-size: 1.2rem !important;
  line-height: 1.2rem !important;
  color: #c8c5c5 !important;
  font-weight: 300 !important;
  margin-inline-start: 5.2rem !important;
}

.ant-notification-notice-message {
  font-size: 1.6rem !important;
  font-weight: 700 !important;
  color: var(--WHITE) !important;
  line-height: 1.6rem !important;
  margin-bottom: 0.4rem !important;
  margin-inline-start: 5.2rem !important;
}
.notification {
  background-color: #e7eaed;
  box-shadow: 0rem 0.8rem 1rem 0rem #00000033 !important;
  border-radius: 0.6rem !important;
}
.ant-notification-notice.notification.ant-notification-notice-closable {
  height: 7rem !important;
}
.notification .ant-notification-notice-message {
  color: var(--PRIMARY) !important;
}

.notification .ant-notification-notice-description,
.notification .ant-notification-notice-message {
  margin-inline-start: 1rem !important;
  display: block;
  width: 28rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.notification .ant-notification-notice-description {
  color: #142d50bf !important;
}
.notification .ant-notification-notice-close svg path {
  stroke: var(--PRIMARY);
}

.ant-tabs {
  flex: 1;
}
.ant-tabs-content-top,
.ant-tabs-tabpane {
  height: 100%;
}
.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none;
}

.ant-tabs-nav .ant-tabs-nav-wrap {
  border: 1px solid #eee;
  border-radius: 1rem;
  padding: 0 0.8rem;
}

.ant-tabs-tab {
  font-weight: semibold;
  svg {
    path {
      fill-opacity: 0.5;
      fill: #8996a7;
    }
  }
}

.ant-tabs-tab-active {
  svg {
    path {
      fill: var(--PRIMARY);
      fill-opacity: 1;
    }
  }
}

.ant-tabs .ant-tabs-tab {
  padding: 1.4rem 1.1rem;
}

.ant-tabs-top > .ant-tabs-nav {
  margin: 1.2rem 0 3.2rem 0;
}

.ant-tabs .ant-tabs-tab-btn {
  font-size: 14px;
  font-weight: 500;
  color: #142d5080;
  display: flex;
  align-items: center;
}

.ant-tabs .ant-tabs-tab-btn .ant-tabs-tab-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline-end: 9px !important;
}

.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 0.8rem;
}

.ant-breadcrumb-link {
  cursor: pointer;
}

.ant-select-item-option-state {
  display: none !important;
}

.ant-select-item-option-selected {
  background-color: #868b8f6e !important ;
}
.ant-dropdown {
  width: 16rem !important;
}
.ant-dropdown .ant-dropdown-menu {
  border-radius: 6px !important;
}
.ant-picker {
  cursor: pointer;
  border: 1px solid var(--BORDER-DARK);
}
.ant-picker-input {
  color: var(--PRIMARY);
}
.ant-picker-suffix svg {
  fill: var(--PRIMARY);
}
.ant-timeline-item {
  color: var(--PRIMARY) !important;
}
.ant-timeline-item-label {
  font-size: 1.2rem !important;
  text-align: left !important;
}
.ant-timeline-item-head,
.ant-timeline-item-tail {
  inset-inline-start: 8% !important;
}
.ant-timeline-item-content {
  font-size: 1.4rem !important;
  inset-inline-start: calc(10% - 4px) !important;
}
.ant-timeline-item-head-blue {
  border-color: var(--ELLIPSE) !important;
}
.ant-modal-mask {
  background-color: #142d5080 !important;
}
.ant-table-thead {
  background-color: #fdfdfd;
}
.ant-table-cell-row-hover {
  background: var(--TABLE-ROW-HOVER) !important;
}
.ant-modal-body {
  .ant-form-item {
    margin-bottom: 1.6rem;
  }
}
.ant-select {
  .ant-select-selector {
    border: 1px solid var(--PRIMARY) !important;
  }
  .ant-select-selection-placeholder {
    color: var(--PRIMARY) !important;
  }
  .ant-select-arrow {
    color: var(--SECONDARY) !important;
  }
}
.ant-notification-notice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.4rem 1.6rem !important;
  .ant-notification-notice-close {
    position: static !important;
    svg {
      fill: white;
    }
  }
  .ant-notification-notice-icon {
    top: 50%;
    transform: translateY(-50%);
    margin-right: 1.6rem;
  }
}
.ant-select-dropdown {
  .ant-select-item {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important;
  }
  .ant-select-item-option-active {
    background-color: #f1f7ff !important;
  }
  .ant-select-item-option-content {
    & span {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

/* Override Antd Timeline CSS */

.ant-timeline .ant-timeline-item-content {
  margin-inline-start: 110px;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-inline-start: 64px;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-tail {
  margin-inline-start: 68px;
}

.user-log .ant-timeline .ant-timeline-item-tail {
  border-inline-start: 2px solid transparent !important;
}

.user-log .ant-timeline .ant-timeline-item-head {
  background-color: #8893a7 !important;
}

.ant-dropdown .ant-dropdown-menu:has(.menu-collapsed) {
  margin-left: 1rem !important;
}

.sidebar__collapsed .dot {
  margin-left: -1rem !important;
}

.ant-cascader-menus > .ant-cascader-menu {
  min-width: 13.6rem !important;
  padding: 0px !important;
  height: auto !important;
  border-radius: 6px;
  border: 1px solid var(--PAGE-BORDER);
  border-collapse: collapse;
}
.ant-cascader-dropdown.ant-cascader-menu {
  box-shadow: 0px 1px 2px var(--sds-size-depth-025) #00000040;
}
.ant-cascader-dropdown .ant-cascader-menu-item {
  padding: 1.2rem 3.2rem 1.2rem 1.2rem;
  border-radius: 0px;
  border-collapse: collapse;
  color: var(--PRIMARY);
  font-weight: 500;
  border-bottom: 1px solid var(--PAGE-BORDER);
}
.ant-cascader-menu-item.ant-cascader-menu-item-expand {
  padding-right: 2rem !important;
}
.ant-cascader-menu-item:hover,
.ant-cascader-menu-item-active {
  background-color: #f6f9fe !important;
  color: var(--PRIMARY);
  font-weight: 600 !important;
}
.ant-cascader-menus {
  position: fixed;
  background: var(--WHITE);
}
.ant-cascader-menus > .ant-cascader-menu:last-child {
  background: var(--WHITE);
  position: absolute;
  left: 100%;
  top: 48%;
  transform: translateX(0);
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #34a853;
  border-color: #34a853;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon .anticon svg {
  fill: white;
}

.ant-steps {
  width: 18rem;
}

.ant-steps .ant-steps-item-title,
.ant-steps.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding: 0 !important;
}

.ant-steps .ant-steps-item-icon {
  margin: 0 !important;
}
